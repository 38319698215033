/* @import url('http://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap'); */

* {
  font-family: "Poppins", sans-serif;
  /* font-weight: 500; */
}

.fullscreen-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Set video height to 100% of viewport height */
  overflow: hidden;
  z-index: -1;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.above-video {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.content {
  z-index: 1;
  margin-top: 100vh;
  padding-top: 1.5%;
  background-color: white;
}

.above-video-text {
  width: 100vw;
  padding: 0 5%;
}

/* Desktop Screens */
@media (min-width: 577px) {
  .above-video h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 6rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
  }

  .above-video p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
  }
}

/* Mobile Screens */
@media (max-width: 576px) {
  .above-video h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 4rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
  }

  .above-video p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
  }
}

/* E3 */
@media (min-width: 991px) {
  .dynamic-paddingx {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .dynamic-heading {
    font-size: 2.8em;
    font-weight: bold;
    color: #ffa500;
    padding-bottom: 1rem;
  }

  .dynamic-text {
    font-size: 1.5rem;
  }

  .image-heading {
    width: 90%;
  }
  .e3-dynamic-paddingx {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .e3-dynamic-heading {
    font-size: 2.5em;
    font-weight: bold;
  }

  .e3-dynamic-text {
    font-size: 1.5rem;
    /* font-weight: bold; */
  }
  .parent {
    position: relative;
  }
  .bd-pattern {
    position: absolute;
    right: 0px;
    height: 10%;
  }
  .e3-button-text {
    font-size: 1.8rem;
  }
}

@media (max-width: 991px) {
  dynamic-paddingx {
    padding-left: 0;
    padding-right: 0;
  }
  .dynamic-heading {
    font-size: 2.5em;
    font-weight: bold;
    color: #ffa500;
  }
  .dynamic-text {
    font-size: 1.1rem;
    /* font-weight: bold; */
  }
  .image-heading {
    width: 90%;
  }
  .e3-dynamic-paddingx {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .e3-dynamic-heading {
    font-size: 2.2em;
    font-weight: bold;
    color: #ffa500;
  }
  .e3-dynamic-text {
    font-size: 1.2rem;
    /* font-weight: bold; */
  }
  .bd-pattern {
    display: none;
  }
}

/* header.css */
@media (min-width: 577px) {
  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65vh;
    overflow: hidden;
    background-color: #000000;
    z-index: -1;
  }

  .page-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    /* padding-top: ; */
  }

  .page-header h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 5rem;
    /* color: #ffa500; */
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
  }
  .page-header p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-top: 1.5rem;
  }

  .below-header {
    margin-top: 65vh;
  }
}

/* Mobile Screens */
@media (max-width: 576px) {
  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    background-color: #000000;
    z-index: -1;
  }

  .page-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
  }

  .page-header h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 3.5rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
  }
  .page-header p {
    display: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-top: 1.5rem;
  }

  .below-header {
    margin-top: 50vh;
  }
}

/* Timeline css */
.top {
  height: 350px;
}

.vertical-timeline::before {
  background: black !important;
}
.vertical-timeline::before {
  background: black !important;
}

/* Extra Small Devices, Phones */
@media (min-width: 576px) and (max-width: 767px) {
  .borderRight {
    border-left: 0px solid #000000;
  }
  .borderLeft {
    border-left: 0px solid #000000;
  }
}

/* Medium Devices, Desktops */
@media (min-width: 767px) {
  .borderLeft {
    border-left: 4px solid #000000;
  }
  .borderRight {
    border-right: 4px solid #000000;
  }
}

.media-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four images per row */
  gap: 20px; /* Increased space between images */
  padding: 20px;
}

.media-image-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden; /* Prevent overflow from scaling image */
  position: relative;
  border-radius: 8px;
  transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
}

.media-image {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Maintain aspect ratio, crop if necessary */
  display: block;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.media-image-container:hover {
  box-shadow: 0 0 15px rgba(0.8, 0.8, 0.8, 0.9); /* Shadowy grey outline on hover */
}

.media-image-container:hover .media-image {
  transform: scale(1.1); /* Scale the image by 10% */
}

@media (max-width: 991px) {
  .media-gallery {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two images per row on medium screens */
  }
}

@media (max-width: 576px) {
  .media-gallery {
    grid-template-columns: 1fr; /* One image per row on small screens */
  }
}
