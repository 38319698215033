.social-icons {
  height: 45px;
  width: 45px;
}

.social-links:hover {
  transform: scale(1.3);
  transition: all 0.1s ease-in-out;
}

/* small screens */
@media (max-width: 768px) {
  .social-icons {
    height: 30px;
    width: 30px;
  }
}

.footer-background {
  /* dark color */
  background-color: #000000;
}
